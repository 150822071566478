/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */

// https://mathiasbynens.be/notes/globalthis

(function (Object) {
    typeof globalThis !== 'object' && (
        this ? get()
            : (Object.defineProperty(Object.prototype, '_T_', {
                configurable: true,
                get
            }),
            _T_)
    );

    function get() {
        this.globalThis = this;
        delete Object.prototype._T_;
    }
}(Object));
