import '@babel/polyfill';
import '../polyfill/element-remove';
import '../polyfill/global-this';
import '../assets/reset.css';
import '../assets/modal.css';
import init from '@presentation/core';
import VTUndefinedComponent from '@presentation/components/lib/vt-undefined-component';
import VTUndefinedVisualArea from '@presentation/components/lib/vt-undefined-visual-area';
/* vt-cssframeworks-imports */

import  Aside  from '@presentation/components/lib/aside'; 
import  Bottomasid  from '@presentation/components/lib/bottomasid'; 
import  Contents1  from '@presentation/components/lib/contents1'; 
import  Contents2  from '@presentation/components/lib/contents2'; 
import  Contents3  from '@presentation/components/lib/contents3'; 
import  Contents4  from '@presentation/components/lib/contents4'; 
import  Contents5  from '@presentation/components/lib/contents5'; 
import  Contents6  from '@presentation/components/lib/contents6'; 
import  Footer  from '@presentation/components/lib/footer'; 
import  Footer2  from '@presentation/components/lib/footer2'; 
import  Header  from '@presentation/components/lib/header'; 
import  Nav  from '@presentation/components/lib/nav'; 
import  Nav2  from '@presentation/components/lib/nav2'; 
import  Nav3  from '@presentation/components/lib/nav3'; 
import  Wrapper  from '@presentation/components/lib/wrapper'; 
import  VTPanelComponent  from '@presentation/components/lib/vt-panel-component'; 
import  VTRadioComponent  from '@presentation/components/lib/vt-radio-component'; 
import  VTCheckboxComponent  from '@presentation/components/lib/vt-checkbox-component'; 
import  VTTextComponent  from '@presentation/components/lib/vt-text-component'; 
import  VTPopUpComponent  from '@presentation/components/lib/vt-popUp-component'; 
import  VTLineBreakComponent  from '@presentation/components/lib/vt-lineBreak-component'; 
import  VTPasswordComponent  from '@presentation/components/lib/vt-password-component'; 
import  VTImageComponent  from '@presentation/components/lib/vt-image-component'; 
import  VTCustomScriptComponent  from '@presentation/components/lib/vt-customScript-Component'; 
import  VTFlexPanelComponent  from '@presentation/components/lib/vt-flexPanel-component'; 
import  VTFragment  from '@presentation/components/lib/vt-fragment-component'; 


const componentLoader = (componentSelector, isVisualArea) => new Promise((resolve) => {
    switch (componentSelector) {
    
                case 'aside': resolve(Aside); break; 
            
                case 'bottomasid': resolve(Bottomasid); break; 
            
                case 'contents1': resolve(Contents1); break; 
            
                case 'contents2': resolve(Contents2); break; 
            
                case 'contents3': resolve(Contents3); break; 
            
                case 'contents4': resolve(Contents4); break; 
            
                case 'contents5': resolve(Contents5); break; 
            
                case 'contents6': resolve(Contents6); break; 
            
                case 'footer': resolve(Footer); break; 
            
                case 'footer2': resolve(Footer2); break; 
            
                case 'header': resolve(Header); break; 
            
                case 'nav': resolve(Nav); break; 
            
                case 'nav2': resolve(Nav2); break; 
            
                case 'nav3': resolve(Nav3); break; 
            
                case 'Wrapper': resolve(Wrapper); break; 
            
                case 'VTPanelComponent': resolve(VTPanelComponent); break; 
            
                case 'VTRadioComponent': resolve(VTRadioComponent); break; 
            
                case 'VTCheckboxComponent': resolve(VTCheckboxComponent); break; 
            
                case 'VTTextComponent': resolve(VTTextComponent); break; 
            
                case 'VTPopUpComponent': resolve(VTPopUpComponent); break; 
            
                case 'VTLineBreakComponent': resolve(VTLineBreakComponent); break; 
            
                case 'VTPasswordComponent': resolve(VTPasswordComponent); break; 
            
                case 'VTImageComponent': resolve(VTImageComponent); break; 
            
                case 'VTCustomScriptComponent': resolve(VTCustomScriptComponent); break; 
            
                case 'VTFlexPanelComponent': resolve(VTFlexPanelComponent); break; 
            
                case 'VTFragment': resolve(VTFragment); break; 
            
    
            case 'VTButtonComponent':
                import(/* webpackChunkName: "VTButtonComponent" */'@presentation/components/lib/vt-button-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTInputComponent':
                import(/* webpackChunkName: "VTInputComponent" */'@presentation/components/lib/vt-input-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataTableComponent':
                import(/* webpackChunkName: "VTDataTableComponent" */'@presentation/components/lib/vt-datatable-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataTableRowHeadComponent':
                import(/* webpackChunkName: "VTDataTableRowHeadComponent" */'@presentation/components/lib/vt-datatable-row-head-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataTableRowBodyComponent':
                import(/* webpackChunkName: "VTDataTableRowBodyComponent" */'@presentation/components/lib/vt-datatable-row-body-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataTableCellComponent':
                import(/* webpackChunkName: "VTDataTableCellComponent" */'@presentation/components/lib/vt-datatable-cell-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTProgressBarComponent':
                import(/* webpackChunkName: "VTProgressBarComponent" */'@presentation/components/lib/vt-progress-bar-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTListComponent':
                import(/* webpackChunkName: "VTListComponent" */'@presentation/components/lib/vt-list-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTListItemComponent':
                import(/* webpackChunkName: "VTListItemComponent" */'@presentation/components/lib/vt-list-item-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataGridComponent':
                import(/* webpackChunkName: "VTDataGridComponent" */'@presentation/components/lib/vt-datagrid-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataGridHeadComponent':
                import(/* webpackChunkName: "VTDataGridHeadComponent" */'@presentation/components/lib/vt-datagridhead-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDataGridRowComponent':
                import(/* webpackChunkName: "VTDataGridRowComponent" */'@presentation/components/lib/vt-datagridrow-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTMapComponent':
                import(/* webpackChunkName: "VTMapComponent" */'@presentation/components/lib/vt-map-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTMapMarkerComponent':
                import(/* webpackChunkName: "VTMapMarkerComponent" */'@presentation/components/lib/vt-map-marker-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTHiddenComponent':
                import(/* webpackChunkName: "VTHiddenComponent" */'@presentation/components/lib/vt-hidden-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTHTMLAreaComponent':
                import(/* webpackChunkName: "VTHTMLAreaComponent" */'@presentation/components/lib/vt-html-area-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTMenuComponent':
                import(/* webpackChunkName: "VTMenuComponent" */'@presentation/components/lib/vt-menu-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTMonthPickerComponent':
                import(/* webpackChunkName: "VTMonthPickerComponent" */'@presentation/components/lib/vt-monthpicker-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTFlexContainerComponent':
                import(/* webpackChunkName: "VTFlexContainerComponent" */'@presentation/components/lib/vt-flex-container-component').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDropdownComponent':
                import(/* webpackChunkName: "VTDropdownComponent" */'@presentation/components/lib/VTDropdownComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDropdownInputComponent':
                import(/* webpackChunkName: "VTDropdownInputComponent" */'@presentation/components/lib/VTDropdownInputComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDropdownItemComponent':
                import(/* webpackChunkName: "VTDropdownItemComponent" */'@presentation/components/lib/VTDropdownItemComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'VTDropdownListComponent':
                import(/* webpackChunkName: "VTDropdownListComponent" */'@presentation/components/lib/VTDropdownListComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'DSBarChartsComponent':
                import(/* webpackChunkName: "DSBarChartsComponent" */'@presentation/components/lib/DSBarChartsComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'DSRadioComponent':
                import(/* webpackChunkName: "DSRadioComponent" */'@presentation/components/lib/DSRadioComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'DSProgressIndicatorComponent':
                import(/* webpackChunkName: "DSProgressIndicatorComponent" */'@presentation/components/lib/DSProgressIndicatorComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'DSCheckboxComponent':
                import(/* webpackChunkName: "DSCheckboxComponent" */'@presentation/components/lib/DSCheckboxComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
            case 'DSSwitchComponent':
                import(/* webpackChunkName: "DSSwitchComponent" */'@presentation/components/lib/DSSwitchComponent').then((ComponentDefinition) => {
                    resolve(ComponentDefinition.default);
                }); break; 

            
    default:
        if (isVisualArea) {
            resolve(VTUndefinedVisualArea);
        }
        resolve(VTUndefinedComponent);
    }
});

const plugins = {};
const devTools = {};
const start = () => init(componentLoader, plugins, devTools);
start.extFunction = () => alert('nueva funcion');
start.plugins = plugins;
start.devTools = devTools;
start();
window.app = start;
