/*
 * Custom polyfill to add the remove method to all elements
 */
[Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
        return;
    }
    Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        // eslint-disable-next-line func-names
        value: function remove() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        }
    });
});
